import * as React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import AboutHeader from '../components/about_header'
import { StaticImage } from 'gatsby-plugin-image'
import Container from '../components/container'
import DemoForm from '../components/demo_form'

const AboutPage = () => (
  <Layout>
    <Seo
      title='About'
      description='Roadster offers Omnichannel Commerce solutions, which deliver the same seamless and streamlined shopping experience as customers switch between your site and your store.'
    />
    <AboutHeader
      title='Driving Innovation'
      subtitle='See where we’ve come from and where we’re going.' />
    <div className='Section Section--condensed text-center'>
      <Container>
        <div className='Section-text'>
          At Roadster, our mission is to make the process of car buying and selling better for everyone involved. We do it by offering Omnichannel Commerce solutions, which deliver the same seamless and streamlined shopping experience as customers switch between your site and your store.
        </div>
      </Container>
    </div>
    <div className='Section pt-0'>
      <Container>
        <div className='grid sm:grid-cols-2 md:grid-cols-12 items-center'>
          <div className='md:col-span-5'>
            <div className='Section-text'>
              <h2 className='text-xl md:text-2xl font-light'>
                We’re driven to help you.
              </h2>
              <ul>
                <li>Offer modern shopping conveniences</li>
                <li>Share transparent pricing, instantly</li>
                <li>Rethink traditional sales approaches</li>
                <li>Increase efficiencies</li>
                <li>Maximize customer satisfaction</li>
                <li>Gain a competitive advantage</li>
              </ul>
            </div>
          </div>
          <div className='md:col-span-7 sm:order-first'>
            <StaticImage
              src='../images/01_ExpressScreen.jpg'
              placeholder='blurred'
              alt=''
              width={767}
            />
          </div>
        </div>
      </Container>
    </div>
    <div className='Section pt-0'>
      <Container>
        <div className='grid sm:grid-cols-2 gap-4 items-center'>
          <div className='Section-text'>
            <h2 className='text-xl md:text-2xl font-light'>
              We’ve learned the business by living it.
            </h2>
            <p>
              We’ve learned the business by living it. We bought a car brokerage in 2014 and taught ourselves how to sell cars. We were selling about 150 cars per month exclusively online in the U.S. via Roadster Concierge.
            </p>
            <p>
              It was a humbling experience and it gave us huge respect for what dealers do every day, along with ideas for how we could make transactions faster and easier for you and your customers.
            </p>
          </div>
          <StaticImage
            src='../images/01_HappyCustomer.jpg'
            placeholder='blurred'
            alt=''
            width={630}
            className='relative sm:-right-container'
          />
        </div>
      </Container>
    </div>
    <div className='Section'>
      <div className='relative'>
        <div className='lg:absolute left-0 top-0 w-full z-1'>
          <Container className='text-center'>
            <div className='Section-text lg:pt-4 mb-4' style={{ maxWidth: '52em' }}>
              <h2 className='text-xl md:text-2xl font-light'>
                Our new focus
              </h2>
              <p>
                In 2016, we pivoted the business and began offering our software as a service to dealerships. This is now 100% of our company focus.
              </p>
              <p>
                The Roadster Express product portfolio has been shaped and sharpened by countless conversations we’ve had with our dealer partners. And our commerce solutions continue to evolve and expand to anticipate customers’ and dealers’ greatest needs.
              </p>
              <p>
                As we pave the road for modern-day car commerce, we can’t wait to see where Express takes you.
              </p>
            </div>
          </Container>
        </div>
        <div className='flex justify-center'>
          <StaticImage
            src='../images/02_OurFocus.jpg'
            alt=''
            placeholder='blurred'
            qualithy={100}
          />
        </div>
      </div>
    </div>
    <div className='Section'>
      <Container>
        <DemoForm />
      </Container>
    </div>
  </Layout>
)

export default AboutPage
